<template lang="pug">
.wf-table.full-box
  DataTable(
    ref="dataTable"
    serverSide
    resource="/meterStatus/list"
    size="medium"
    :filterFields="filterFields"
    :columns="columns"
    :toolbarButtonList="toolbarButtonList"
    :operateButtonList="operateButtonList"
    :defaultSortBy="[]")
  el-drawer(
    :title="formTitle"
    size="40%"
    :visible.sync="drawer"
    :modal="true"
    append-to-body
    :before-close="handleClose"
    @open="handleDrawerOpen")
      .form-box.full-box
        DataForm(
          ref="form"
          v-model="formData"
          buttonFixed
          buttonAlign="right"
          :formFields="formFields"
          :buttonList="buttonList")
  </template>

<script>
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'MeterStatusManage',
  computed: {
    formTitle () {
      switch (this.formSubType) {
        case 'add':
          return '新增'
        case 'edit':
          return '修改'
        default:
          return '详情'
      }
    }
  },
  data () {
    const actionRights = this.$getPageActions([
      { name: 'add', url: '/meterRead/meterStatusManage/add' },
      { name: 'edit', url: '/meterRead/meterStatusManage/edit' },
      { name: 'delete', url: '/meterRead/meterStatusManage/delete' }
    ])
    return {
      // 侧拉表单是否展开
      drawer: false,
      // 表格筛选表单配置
      filterFields: [{
        name: 'name',
        label: '名称',
        form: {
          tag: 'input'
        }
      }],
      // 表格列配置
      columns: [
        { name: 'code', label: '编码' },
        { name: 'name', label: '名称' },
        // { name: 'enable', label: '是否展示', render: { type: 'yesOrNo' } },
        { name: 'remark', label: '备注', minWidth: 220 }
      ],
      // 表格数据
      tableData: [],
      toolbarButtonList: actionRights.add ? [
        {
          label: '新增',
          name: 'add',
          type: 'primary',
          func: () => {
            this.formSubType = 'add'
            this.initFormData = { enable: true }
            this.formData = { enable: true }
            this.drawer = true
          }
        }
      ] : [],
      operateButtonList: [{
        label: '编辑',
        name: 'edit',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.edit,
        func: ({ data }) => {
          this.formData = cloneDeep(data)
          this.initFormData = cloneDeep(data)
          this.formSubType = 'edit'
          this.drawer = true
        }
      }, {
        label: '删除',
        name: 'delete',
        type: 'text',
        textType: 'danger',
        showabled: actionRights.delete,
        func: this.handleDelete
      }],
      // 表单数据
      initFormData: {},
      formData: {},
      // 表单提交类型
      formSubType: '', // add/edit
      formFields: [
        { name: 'code', label: '编码', form: { tag: 'input', rules: [{ required: true, message: '必填' }] } },
        { name: 'name', label: '名称', form: { tag: 'input', rules: [{ required: true, message: '必填' }] } },
        // { name: 'enable', label: '是否展示', form: { tag: 'switch' } },
        { name: 'remark', label: '备注', form: { tag: 'input', inputType: 'textarea', minRows: 2 } }
      ],
      formSubFuncMap: new Map([
        ['add', 'handleAdd'],
        ['edit', 'handleUpdate']
      ]),
      buttonList: [{
        label: '提交',
        type: 'primary',
        validate: true,
        func: ({ formData }) => {
          return new Promise((resolve, reject) => {
            const name = this.formSubFuncMap.get(this.formSubType)
            this[name](formData)
              .then(resolve)
              .catch(reject)
          })
        }
      }, {
        label: '重置',
        func: this.resetForm
      }]
    }
  },
  methods: {
    getData () {
      this.$refs.dataTable.reRenderTableData()
    },
    resetForm () {
      this.$set(this, 'formData', cloneDeep(this.initFormData))
    },
    handleDrawerOpen () {
      const formRef = this.$refs.form
      formRef && formRef.$refs.dataform.clearValidate()
    },
    handleClose () {
      this.drawer = false
      this.formSubType = ''
      this.initFormData = {}
      this.resetForm()
    },
    handleAdd (data) {
      return new Promise((resolve, reject) => {
        this.$post({
          url: '/meterStatus/save',
          data
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            this.$message.success('新增成功')
            resolve('success')
            this.handleClose()
            this.getData()
          })
          .catch(reject)
      })
    },
    handleUpdate (data) {
      return new Promise((resolve, reject) => {
        this.$patch({
          url: `/meterStatus/update/${data.id}`,
          data
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            this.$message.success('修改成功')
            resolve('success')
            this.handleClose()
            this.getData()
          })
          .catch(reject)
      })
    },
    handleDelete ({ data }) {
      return new Promise((resolve, reject) => {
        this.$patch({
          url: `/meterStatus/delete/${data.id}`
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            this.$message.success('删除成功')
            resolve('success')
            this.getData()
          })
          .catch(reject)
      })
    }
  },
  created () {
  }
}
</script>

<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.form-box
  position: relative
  padding-right: 8px
</style>
